import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import ExpandingButton from "@/components/Buttons/ExpandingButton";
import TypeAnimationText from "@/components/Shared/TypeAnimationText";
import Link from "next/link";
import localizePath from "@/functions/localizePath";
import useLocale from "@/hooks/useLocale";
import bannerAsset from "@/assets/homepage/asset-banner.png";
import cornerAsset from "@/assets/homepage/asset-corner.png";

export default function HomePageBanner({ path = "main-banner" }) {
  const locale = useLocale();

  return (
    <div
      className="container flex flex-col justify-center h-screen sm:h-full homepage"
      id="image-container"
    >
      <img
        className="absolute right-0 top-0"
        src={cornerAsset ?? ""}
        width={800}
        height={800}
        alt=""
      />
      <img
        className="absolute left-0 top-5 lg:top-[206px]"
        src={bannerAsset ?? ""}
        width={450}
        height={653}
        alt=""
      />
      <h1 className="pt-[250px] md:pt-[120px] pb-[60px] relative z-10 home-page-hero-text">
        <p className="text-5xl leading-[70px] sm:text-[40px]/[50px] md:text-6xl lg:text-[100px] font-bold text-center lg:leading-[107px]">
          <CustomSourceFlowText
            title="Experts in"
            path={`dynamic1.title-1.${path}`}
            locale={locale}
          />
          <br />
        </p>
        <TypeAnimationText />
        <p className="text-5xl leading-[70px] sm:text-[40px]/[50px] md:text-6xl lg:text-[100px] font-bold text-center lg:leading-[107px] top-[-10px] relative">
          <CustomSourceFlowText
            title="recruitment"
            path={`dynamic1.title-2.${path}`}
            locale={locale}
          />
        </p>
      </h1>
      <div className="flex justify-center gap-10 pb-10 relative z-10 content-center flex-wrap">
        <ExpandingButton
          url={localizePath("/jobs", locale)}
          color="white"
          bgColor="transparent"
          hoverBGColor="white"
          hoverColor="#1B0D34"
        >
          <CustomSourceFlowText
            title="Search jobs"
            path="home.banner.search.jobs"
            locale={locale}
          />
        </ExpandingButton>
        <div className="flex justify-center content-center flex-wrap">
          <div className="text-white group w-max flex content-center flex-wrap">
            <Link
              className="text-white text-base sm:text-xl text-center group text-decoration-none group-hover:text-white block nb-5 relative"
              href={localizePath("/vacancies", locale)}
            >
              <CustomSourceFlowText
                title="Find Talent"
                path="home.banner.find.talent"
                locale={locale}
              />
              <span className="w-0 bg-white h-[1px] transition-all block group-hover:w-full mt-[0px]"></span>
            </Link>
          </div>
        </div>
      </div>
      <div className="z-index-1 position-relative sm:w-8/12">
        <p className="text-[18px] sm:text-xl/[35px] md:text-[30px]/[42px] pb-4 mt-[200px] font-normal">
          <CustomSourceFlowText
            title="Born in 2002, Lawrence Harvey isn’t your typical tech recruitment brand. We specialise in tech recruitment that breaks the mould, providing an unwavering reputation for delivering unparalleled results to clients and candidates in both permanent and contract solutions."
            path={`dynamic1.title-4.${path}`}
            locale={locale}
          />
        </p>
      </div>
    </div>
  );
}
